/*
 * @Description:
 * @Author: tianyu
 * @Email: tianyud@zbj.com
 * @Date: 2021-02-19 00:08:30
 * @LastEditors: tianyu
 * @LastEditTime: 2021-02-20 11:04:45
 */

import { createStore } from 'redux'
import reducer from './reducer'
export default createStore(reducer)
