/*
 * @Description:路由
 * @Author: tianyu
 * @Email: tianyud@zbj.com
 * @Date: 2021-02-10 14:14:31
 * @LastEditors: tianyu
 * @LastEditTime: 2021-03-31 09:36:16
 */
import { ReactNode, lazy } from 'react'
import { objType } from '@/globalType'
import session from '@/utils/session'

interface routesType {
  path: string
  exact?: boolean
  component?: ReactNode
  meta?: objType
  redirect?: string
  isShow?: boolean
  children?: routesType[]
  childrenMenu?: boolean
  icon?: any
}
export const routes: routesType[] = [
  {
    path: '/login',
    exact: true,
    component: lazy(() => import('@/pages/login')),
    meta: {
      title: '登录'
    }
  },
  {
    path: '/',
    component: lazy(() => import('@/layout')),
    redirect: '/home',
    children: []
  }
]
//router title
export const setTitle = (data: string | number) => {
  let websiteTitle = session.get('websiteData').cityName
  typeof data === 'number'
    ? (document.title = data + '')
    : (document.title = websiteTitle + ' | ' + data)
}
