/*
 * @Description:
 * @Author: tianyu
 * @Email: jackTian9527@163.com
 * @Date: 2021-02-24 17:46:10
 * @LastEditors: tianyu
 * @LastEditTime: 2021-03-12 09:59:47
 */
import { useHistory } from 'react-router-dom'
import { Button, Result } from 'antd'
import React from 'react'

function NoFoundPage() {
  let history = useHistory()
  return (
    <Result
      className="bg-white"
      status="404"
      title="404"
      subTitle="找不到你要访问的页面"
      extra={
        <Button type="primary" onClick={() => history.goBack()}>
          回到上一页
        </Button>
      }
    />
  )
}

export default NoFoundPage
