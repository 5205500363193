/*
 * @Description:请求封装
 * @Author: tianyu
 * @Email: tianyud@zbj.com
 * @Date: 2021-02-10 02:35:19
 * @LastEditors: tianyu
 * @LastEditTime: 2021-04-01 16:27:58
 */
import axios from 'axios'
import { objType } from '@/globalType'
import { message } from 'antd'
import session from '@/utils/session'
import store from '@/store'
import { tableLoading, tableChildLoading, buttonLoading } from '@/store/actions'
import { clearLogin } from '@/utils/global'

/**
 *
 * @param type 请求类型
 * @param data 请求数据
 */
function urlParamsHandle(type: string, data: any): string {
  let urlParams = []
  let testType = /(get|delete)/
  if (testType.test(type) && data) {
    for (let d in data) {
      urlParams.push(d + '=' + encodeURI(data[d]))
    }
  }
  return urlParams.length ? '?' + urlParams.join('&') : ''
}

interface headers {
  url: string //请求API
  method: any //请求类型
  data?: any //请求数据
  headers?: objType //请求头信息
  tableLoading?: boolean //tableloading
  tableChildLoading?: boolean //tableChild
  buttonLoading?: boolean //buttonLoading
  custom?: any //自定义数据
}
function getToken() {
  let tokenData = session.getLoca('token')
  let data = tokenData ? tokenData.data.token : ''
  return data
}
//全局请求配置
const req = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  // baseURL: 'http://test.phjrfw.com/api/back',   // 测试环境
  // baseURL: 'http://phjrfw.com/api/back',   // 正式环境
  // baseURL: 'http://172.17.61.198:9000/api/back',  // 袁枫地址
  timeout: 3000000
})

// 请求拦截器
req.interceptors.request.use(
  function (config) {
    config.headers.Authorization = getToken() //默认带上请求头
    // 在发送请求之前做些什么
    return config
  },
  function (error) {
    // 对请求错误做些什么
    return Promise.reject(error)
  }
)

//响应数据处理
req.interceptors.response.use(
  function (response) {
    // 对响应数据做点什么
    return response
  },
  function (error) {
    warnError(error.message)
    closeLoading(true)
    // 对响应错误做点什么
    return Promise.reject(error)
  }
)
let warningTimer: any = '' //错误记录
function warnError(msg: any) {
  if (!warningTimer) {
    //错误x秒内只弹出一次
    warningTimer = message.warning(msg)
    setTimeout(() => {
      warningTimer = null
    }, 3000)
  }
}
  export default function request(headerData: headers, onUploadProgress: any = false): any {
  let {
    url,
    method = 'get',
    headers,
    data,
    tableLoading = false,
    tableChildLoading = false,
    custom = {},
    buttonLoading: buttonLoadingStatus = false
  } = headerData
  tablePage({ tableLoading, tableChildLoading }) //tableLoading
  buttonLoadings(buttonLoadingStatus)
  if (data && !data.lastModifiedDate && !data.lastModified) {
    //不是文件流执行
    data = JSON.parse(
      JSON.stringify(data, (key, val) => {
        if (method === 'put' || method === 'post') {
          return val === undefined ? '' : val
        } else {
          return val === '' ? undefined : val
        }
      })
    )
  }
  url = url + urlParamsHandle(method, data) //显示参数处理
  return req
    .request({
      url,
      method,
      data,
      headers,
      timeout: custom.timeout || undefined,
      onUploadProgress(e:any) {
        if (onUploadProgress  && onUploadProgress.constructor === Function) {
          onUploadProgress(e)
        }
      }
    })
    .then(res => {
      let data = res.data
      if (tableLoading || tableChildLoading) {
        tablePage(data) //分页更新
        setTimeout(() => {
          //关闭tableLoading
          closeLoading()
        }, 100)
      }
      //修改更新
      if (buttonLoadingStatus) {
        closebuttonLoading()
      }

      if (data.code !== 200 && custom.error !== false) {
        if (data.code === 1000) {
          clearLogin()
          // eslint-disable-next-line no-throw-literal
          throw 'clear cache'
        }
        warnError(data.msg||'系统未知异常!')
        // eslint-disable-next-line no-throw-literal
        throw 'code not 200'
      }
      return data
    })
}

function tablePage(data?: any) {
  //分页处理
  if (data.tableLoading || data.tableChildLoading) {
    //分页加载
    store.dispatch(tableLoading(data.tableLoading))
    store.dispatch(tableChildLoading(data.tableChildLoading))
    return
  }
}

//buttonLoading
function buttonLoadings(bol: boolean) {
  if (bol) store.dispatch(buttonLoading(bol))
}
function closebuttonLoading() {
  setTimeout(() => {
    store.dispatch(buttonLoading(false))
  }, 300)
}

function closeLoading(err?: boolean) {
  store.dispatch(tableLoading(false))
  store.dispatch(tableChildLoading(false))
  if (err) {
    closebuttonLoading()
  }
}

