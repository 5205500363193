/*
 * @Description:
 * @Author: tianyu
 * @Email: tianyud@zbj.com
 * @Date: 2021-02-17 00:19:35
 * @LastEditors: tianyu
 * @LastEditTime: 2021-02-17 00:31:54
 */
import { Spin, Space } from 'antd'
import './loading.scss'
import React from 'react'
function Loading(props: any) {
  let { size = 'default' } = props
  console.log(size, '---size---')
  return (
    <div className="loading-box" style={{ width: '100%', height: '100vh' }}>
      <div className="loading-auto">
        <Space>
          <Spin size={size} />
        </Space>
      </div>
    </div>
  )
}
export default Loading
